<template>
  <section
    ref="container"
    class="text-in-abschnitten"
  >
    <div ref="navigation" class="navigation">
      <overflow :style="{'--overlay-color': 'var(--color-light-green)'}">
        <a
          v-for="(abschnitt, idx) in payload.abschnitte"
          :id="'title-' + abschnitt.id"
          :key="'title-' + abschnitt.id"
          :class="{ 'text-grey': abschnitt.id === selectedSection.id }"
          href="#"
          class="mb-4 no-underline block font-sans"
          @click.prevent="nextSection(idx)"
          v-text="abschnitt.title"
        />
      </overflow>
    </div>
    <div ref="container" class="constrain-4/5">
      <div class="md:relative md:left-1/3 md:w-2/3">
        <transition :name="transitionName" mode="out-in" tag="div">
          <div :key="selectedSection.id">
            <h3 v-text="selectedSection.title" />
            <div v-html="selectedSection.text" />
          </div>
        </transition>
      </div>
    </div>
    <div class="constrain-4/5 text-right mt-8">
      <a
        href="#"
        class="hover:text-white"
        @click.prevent="nextSection(null)"
      >
        <span class="btn inline">
          Weiterlesen
        </span>
        <Arrow class="w-4 transform -rotate-90 inline-block ml-4" />
      </a>
    </div>
  </section>
</template>

<script>
import { nextTick, ref } from 'vue';
import Arrow from '@/assets/images/arrow.svg';
import Overflow from '@/components/utils/Overflow.vue';
import { useResizeObserver } from '@/composables/observer';

export default {
  components: { Arrow, Overflow },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const selectedSection = ref(props.payload.abschnitte[0]);
    const navigation = ref(null);
    const container = useResizeObserver((entry) => {
      const { height } = navigation.value.getBoundingClientRect();
      entry.target.style.minHeight = `${height}px`;
    });

    return {
      selectedSection,
      navigation,
      container,
      transitionName: ref('slide-left'),
    };
  },
  methods: {
    nextSection(idx = null) {
      const currentIdx = this.payload.abschnitte.findIndex((a) => a.id === this.selectedSection.id);
      const nextIdx = idx === null ? currentIdx + 1 : idx;
      if (nextIdx > currentIdx) {
        this.transitionName = 'slide-left';
      } else {
        this.transitionName = 'slide-right';
      }

      this.$nextTick(() => {
        this.selectedSection = this.payload.abschnitte[nextIdx];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-in-abschnitten {
  @apply bg-green-light pt-12 pb-24 relative text-sm;
}

.navigation {
  @apply bg-green-light z-10 pb-4;

  box-sizing: border-box;
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);

  a {
    min-width: 200px;
    display: inline;
    text-align: left;
    margin-right: 1rem;
  }

  @screen md {
    @apply block py-12;
    @apply absolute top-0 left-0 bottom-0 w-1/3;

    a {
      min-width: auto;
      display: block;
      margin-right: 0;
    }
  }
}
</style>
