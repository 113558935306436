<template>
  <div class="overflow-wrapper">
    <div ref="wrapper" class="overflow-container">
      <slot />
    </div>
    <div class="overlay left" :class="{'active': realX > 10}" />
    <div class="overlay right" :class="{'active': realX <= width - 10}" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      down: false,
      timestamp: null,
      initial: 0,
      x: 0,
      realX: 0,
      lastX: 0,
      width: 0,
      velocity: 0,
    };
  },

  mounted() {
    this.$refs.wrapper.addEventListener('mousedown', this.onDown);
    window.addEventListener('mousemove', this.onMove);
    window.addEventListener('mouseup', this.onUp);

    this.$refs.wrapper.addEventListener('touchstart', this.onDown);
    window.addEventListener('touchmove', this.onMove);
    window.addEventListener('touchend', this.onUp);

    this.width = this.$refs.wrapper.scrollWidth - this.$refs.wrapper.offsetWidth;
  },

  beforeUnmount() {
    this.$refs.wrapper.addEventListener('mousedown', this.onDown);
    window.addEventListener('mousemove', this.onMove);
    window.addEventListener('mouseup', this.onUp);

    this.$refs.wrapper.removeEventListener('touchstart', this.onDown);
    window.removeEventListener('touchmove', this.onMove);
    window.removeEventListener('touchend', this.onUp);
  },

  methods: {
    fade(velocity, cb) {
      const timeConstant = 325;

      const amplitude = 0.8 * velocity;
      const target = Math.round(amplitude);
      const timestamp = Date.now();

      const autoScroll = (_) => {
        let elapsed; let
          delta;
        if (amplitude) {
          elapsed = Date.now() - timestamp;
          delta = -amplitude * Math.exp(-elapsed / timeConstant);
          if (delta > 0.5 || delta < -0.5) {
            if (!cb(target + delta)) return;
            requestAnimationFrame(autoScroll);
          } else {
            cb(target);
          }
        }
      };

      autoScroll();
    },
    onDown(e) {
      this.down = e.clientX || e.touches[0].clientX;
      this.timestamp = Date.now();
      this.lastX = this.$refs.wrapper.scrollLeft;
      this.x = this.lastX;
      this.initial = this.x;
    },
    onMove(e) {
      if (this.down) {
        e.preventDefault();

        const clientX = (e.touches && e.touches[0].clientX) || e.clientX;

        // update position
        this.x = this.initial + this.down - clientX;
        this.$refs.wrapper.scrollTo(this.x, 0);
        this.realX = this.$refs.wrapper.scrollLeft;

        // velocity
        const deltaX = this.lastX - clientX;
        this.lastX = clientX;

        // for velocity
        const now = Date.now();
        const elapsed = now - this.timestamp;
        this.timestamp = now;

        const v = (600 * deltaX) / (1 + elapsed);
        this.velocity = 0.8 * v + 0.2 * this.velocity;
      }
    },
    onUp(e) {
      if (this.down) {
        this.down = 0;

        // cancel if not moved
        if (Math.abs(this.initial - this.x) < 5) return;

        e.preventDefault();

        this.fade(this.velocity, (offset) => {
          if (this.down) return false;

          // update position
          this.$refs.wrapper.scrollTo(this.x + offset, 0);
          this.realX = this.$refs.wrapper.scrollLeft;

          return true;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-wrapper {
  width: 100%;
}

.overflow-wrapper {
  position: relative;
  user-select: none;
  cursor: col-resize;

  @screen md {
    position: static;
    user-select: auto;
    cursor: auto;
  }
}

.overflow-container {
  white-space: nowrap;
  overflow-x: hidden;
  touch-action: pan-x;

  @screen md {
    white-space: normal;
    overflow-x: visible;
    touch-action: auto;
  }

  & > * {
    display: inline-block;
    width: auto;
    max-width: none;

    @screen md {
      display: inline;
      width: auto;
      max-width: auto;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-in-out;

  @screen md {
    display: none;
  }

  &.left {
    left: 0;
    background-image: linear-gradient(to right, var(--overlay-color), rgba(255, 255, 255, 0));
  }

  &.right {
    right: 0;
    background-image: linear-gradient(to left, var(--overlay-color), rgba(255, 255, 255, 0));
  }

  &.active {
    opacity: 1;
  }
}
</style>
